import React, { useRef, useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import classNames from 'classnames';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { featuredSliderPropTypes, defaultSlide } from './proptypes';
import PhillipsTranslations from '../PhillipsTranslations/PhillipsTranslations';
import CMSControls from '../CMS/HomePage/CMSControls';
import EditOverlay from '../CMS/HomePage/EditOverlay';

const ImageSlide = ({ url, imageUrl }) => (
  <a href={url} className="homepage__link">
    <div
      className="homepage__hero__image"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover'
      }}
    />
    <img src={imageUrl} alt={url} />
  </a>
);
ImageSlide.propTypes = {
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired
};

const HomePageHero = ({ editable, interval, language, autoplay, featuredSlider, getI18nLabel, getTranslatedString }) => {
  // const { language, featuredSlider } = useSelector(state => state.elements);
  const [currIndex, setIndex] = useState(0);
  const slider = featuredSlider
    .filter((item) => item.language.toUpperCase() === language.toUpperCase() && (editable || (!editable && item.active)))
    .sort((a, b) => a.displayOrder - b.displayOrder);
  const ref = useRef(null);
  const playInterval = useRef(null);
  const totalCount = slider.length;
  useEffect(() => {
    if (autoplay) {
      playInterval.current = setInterval(() => {
        const total = totalCount;
        setIndex(cI => (cI === total - 1 ? 0 : cI + 1));
      }, interval);
    }
    return () => clearInterval(playInterval.current);
  }, []);

  const scrollDown = () => window.scroll({
    top: ref.current.clientHeight,
    behavior: 'smooth'
  });
  const changeIndex = (int) => {
    if (!isNull(playInterval.current)) {
      clearInterval(playInterval.current);
    }
    const newIndex = (currIndex + int) < 0
      ? totalCount - 1
      : (currIndex + int) === totalCount
        ? 0
        : currIndex + int;
    return setIndex(newIndex);
  };
  const handleClick = (e) => changeIndex(parseInt(e.target.value, 10));
  const handleSwipe = ({ dir }) => {
    const int = dir === 'Left' ? 1 : dir === 'Right' ? -1 : null;
    if (!isNull(int)) {
      changeIndex(int);
    }
  }
  const handlers = useSwipeable({ onSwiped: handleSwipe, delta: 40 });
  return (
    <CMSControls
      editable={editable}
      elementProps={{
        ...defaultSlide,
        language: language.toUpperCase(),
        totalCount: slider.length
      }}
      getI18nLabel={getI18nLabel}
      addType="slide"
      showAdd
      showEdit={false}
    >
      <div className={classNames('homepage__hero', { 'homepage__hero--cms': editable })} ref={ref}>
        <div className="homepage__hero__slider homepage__hero__slider--caption">
          <ul className="homepage__hero__slider__wrapper homepage__hero__slider__wrapper--caption">
            {slider.map(({ htmlCaption, id, url }, index) => (
              <li className={classNames("homepage__hero__slide homepage__hero__slide--caption", { 'homepage__hero__slide--active': index === currIndex })} key={`caption-${id}`} >
                <a
                  className="homepage__link"
                  href={url}
                >
                  <div
                    className="homepage__hero__caption"
                    dangerouslySetInnerHTML={{ __html: htmlCaption }}
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="homepage__hero__slider homepage__hero__slider--image">
          <button
            className="homepage__hero__button homepage__hero__button--left"
            onClick={handleClick}
            type="button"
            value={-1}
          />
          <button
            className="homepage__hero__button homepage__hero__button--right"
            onClick={handleClick}
            type="button"
            value={1}
          />
          <ul className="homepage__hero__slider__wrapper" {...handlers}>
            {slider.map((image, index) => (
              <li
                className={classNames("homepage__hero__slide homepage__hero__slide--image", {
                  'homepage__hero__slide--active': index === currIndex,
                  'homepage__hero__slide--cms': editable
                })}
                key={`image-${image.id}`}
              >
                {editable
                  ? (
                    <EditOverlay
                      language={language}
                      {...image}
                      elementType="slide"
                      totalCount={totalCount}
                      className="edit-overlay__wrapper--slide"
                    >
                      <ImageSlide {...image} />
                    </EditOverlay>
                  )
                  : <ImageSlide {...image} />
                }
              </li>
            ))}
          </ul>
        </div>
        <button
          type="button"
          className="scroll-down"
          onClick={scrollDown}
        />
      </div>
    </CMSControls>
  );
};

HomePageHero.propTypes = {
  editable: PropTypes.bool.isRequired,
  featuredSlider: featuredSliderPropTypes.isRequired,
  interval: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  autoplay: PropTypes.bool.isRequired
};

export default PhillipsTranslations(HomePageHero);
