import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import setI18nLabel from '../../../utils/getI18nLabel';
import { privateSalesInquire } from '../actions';
import sendAnalytics from '../../../utils/sendAnalytics';

const handleAnalytics = ({
  auctionLotPublicId = 0,
  department,
  eventType = 'click',
  lotNumber,
  saleNumber,
  userEmail
}) => {
  let eventAction = 'Inquire - Private Sales';
  let eventLabel = `Private Sales - ${saleNumber} ${lotNumber}`;
  if (saleTitle.toLowerCase().indexOf('perpetual') > -1) {
    eventAction = eventType === 'click' ? 'Inquire - Perpetual Store' : 'Inquiry Submission - Perpetual';
    eventLabel = `Perpetual - ${auctionLotPublicId}`;
  } else if (saleTitle.toLowerCase().indexOf('flawless') > -1 || (!isUndefined(department) && department.toLowerCase() === 'jewelry')) {
    eventAction = eventType === 'click' ? 'Inquire - Flawless' : 'Inquiry Submission - Flawless';
    eventLabel = `Flawless - ${saleNumber} ${lotNumber}`;
  } else {
    eventAction = eventType === 'submit' ? 'Inquiry Submission - Private Sales' : eventAction;
    eventLabel = eventType === 'submit' ? `Private Sales Inquiry Submission - ${saleNumber} ${lotNumber}` : eventLabel;
  }
  sendAnalytics({
    eventCategory: 'Bid Button',
    eventAction: 'Advance Bid Submission',
    eventLabel: eventLabel,
    userEmail: userEmail
  });
};

const Form = ({ description, errorMsg, getI18nLabel, makerName, onSubmit, saleNumber, userLoggedIn}) => {
  const handleSubmit = e => {
    e.preventDefault();
    const data = serialize(e.target, true);
    onSubmit(data);
  };
  const perpetualSaleNumber = typeof sessionStorage !== "undefined" ? sessionStorage.getItem('perpetualSaleNumber'):"";
  const formTitle =
    saleNumber.toUpperCase() === perpetualSaleNumber
      ? getI18nLabel({ label: 'inquireFromTitlePerpetual' })
      : getI18nLabel({ label: 'inquireFormTitlePrivateSales' });
  const formDescription =
    saleNumber.toUpperCase() === perpetualSaleNumber
      ? getI18nLabel({ label: 'inquireFormDescriptionPerpetual', args: [description, makerName] })
      : getI18nLabel({ label: 'inquireFormDescriptionPrivatesale', args: [description, makerName] });
  const formItem = getI18nLabel({ label: 'inquireFormItem', args: [description, makerName] });
  return (
    <form onSubmit={handleSubmit} className="user-form--private-sales__form" name="private-sales-inquiry">
      {errorMsg.length > 0 ? <p className="user-form__message user-form__message--red">{errorMsg}</p> : null}
      <h2 className="user-form__title">{formTitle}</h2>
      <p className="user-form__item">{formItem}</p>
      <p className="user-form__message">{formDescription}</p>
      {!userLoggedIn ? (
        <Fragment>
          <div className="user-form__form-element">
            <label className="user-form__form-element__floating-label">{getI18nLabel({ label: 'name' })}</label>
            <input
              type="text"
              name="name"
              className="user-form__form__input"
              required
            />
          </div>
          <div className="user-form__form-element">
            <label className="user-form__form-element__floating-label">{getI18nLabel({ label: 'email' })}</label>
            <input
              type="email"
              name="email"
              className="user-form__form__input"
              required
            />
          </div>
        </Fragment>
      ) : null}
      <div className="user-form__form-element">
        <label className="user-form__form-element__floating-label">{getI18nLabel({ label: 'message' })}</label>
        <textarea
          className="user-form--private-sales__form__textarea"
          id="comments"
          name="comments"
          rows={4}
        />
      </div>
      <input
        type="submit"
        value={getI18nLabel({ label: 'inquire' })}
        className="user-form--private-sales__form__submit"
      />
    </form>
  );
};

Form.propTypes = {
  description: PropTypes.string.isRequired,
  errorMsg: PropTypes.string.isRequired,
  getI18nLabel: PropTypes.func.isRequired,
  makerName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saleNumber: PropTypes.string.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
};

const InquireForm = ({
  auctionLotPublicId,
  className,
  formOpen,
  lotNumber,
  saleNumber,
  department,
  description,
  makerName,
  isMobile,
  objectNumber,
  saleTitle,
  successMessage
 
}) => {
  const { language, user, inquireForm } = useSelector(({ language, user, inquireForm }) => ({
    language,
    user,
    inquireForm
  }));
  const dispatch = useDispatch();
  const [isOpen, toggleHeight] = useState(formOpen);
  useEffect(() => {
    if (isOpen && isMobile) {
      toggleHeight(!isOpen);
    }
  }, [lotNumber]);
  const handleToggle = () => {
    handleAnalytics({
      auctionLotPublicId,
      department,
      eventType: 'click',
      lotNumber,
      saleNumber,
      saleTitle,
      userEmail: user?.email
    });
    toggleHeight(!isOpen);
  };
  const handleSubmit = formData => {
    const payload = {
      name: user.name,
      email: user.email,
      ...formData,
      objectNumbers: objectNumber.length > 0 ? [objectNumber] : [],
      saleNumber
    };
    dispatch(privateSalesInquire(payload));
  };
  if (inquireForm.status === 'success') {
    handleAnalytics({
      auctionLotPublicId,
      department,
      eventType: 'submit',
      lotNumber,
      saleNumber,
      saleTitle
    });
  }
  const errorMsg = inquireForm.status === 'error' && inquireForm.message.length > 0 ? inquireForm.message : '';
  const getI18nLabel = setI18nLabel(language);
  return (
    <div
      className={classNames(`user-form user-form--private-sales ${className}`, {
        'user-form--private-sales--desktop': !isMobile,
        'user-form--private-sales--close': !isOpen && isMobile
      })}
    >
      {inquireForm.status === 'pending' || inquireForm.status === 'success' ? (
        <div
          className={classNames('user-form--private-sales__status-bar', {
            'user-form__loading': inquireForm.status === 'pending'
          })}
        >
          {inquireForm.status === 'success' && successMessage.length > 0
            ? <span dangerouslySetInnerHTML={{ __html: successMessage }} />
            : inquireForm.message
          }
        </div>
      ) : (
          <Fragment>
            {!isOpen && isMobile ? (
              <button className="user-form--private-sales__button" onClick={handleToggle} type="button">
                {getI18nLabel({ label: 'contactSpecialist' })}
              </button>
            ) : null}
            <Form
              getI18nLabel={getI18nLabel}
              auctionLotPublicId={auctionLotPublicId}
              description={description}
              onSubmit={handleSubmit}
              makerName={makerName}
              errorMsg={errorMsg}
              saleNumber={saleNumber}
              userLoggedIn={user.loggedIn}
            />
          </Fragment>
        )}
    </div>
  );
};

InquireForm.defaultProps = {
  auctionLotPublicId: 0,
  formOpen: false,
  isMobile: false,
  className: '',
  department: '',
  successMessage: ''
};
InquireForm.propTypes = {
  auctionLotPublicId: PropTypes.number,
  className: PropTypes.string,
  department: PropTypes.string,
  description: PropTypes.string.isRequired,
  formOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  lotNumber: PropTypes.number.isRequired,
  makerName: PropTypes.string.isRequired,
  objectNumber: PropTypes.string.isRequired,
  saleNumber: PropTypes.string.isRequired,
  saleTitle: PropTypes.string.isRequired,
  successMessage: PropTypes.string
};

export default InquireForm;
