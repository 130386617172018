import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { defaultContent, featuredContentPropTypes, featuredItemPropTypes } from './proptypes';
import EditOverlay from '../CMS/HomePage/EditOverlay';
import setI18nLanguage from "../../utils/getI18nLabel";

const EditorialItem = ({ htmlCaption, imageUrl, url }) => (
  <a href={url} className="homepage__link">
    <div className="homepage__grid-item__content-wrapper">
      <div
        className="image-container"
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%'
        }}
      />
      <span
        className="caption"
        dangerouslySetInnerHTML={{ __html: htmlCaption }}
      />
    </div>
  </a>
);

EditorialItem.defaultProps = defaultContent;
EditorialItem.propTypes = featuredItemPropTypes;

const HomePageGrid = (props) => {
  const { language, featuredContent, editable, itemsShown } = props;
  const filteredFeaturedContent = featuredContent
    .filter((item) => item.language.toUpperCase() === language.toUpperCase() && (editable || (!editable && item.active)))
    .sort((a, b) => a.displayOrder - b.displayOrder);
  const getI18nLabel = setI18nLanguage(language);
  const [numberOfItemsShown, setItems] = useState(itemsShown >= 4 ? itemsShown : 4);
  const handleMore = () => setItems(numberOfItemsShown + 4);
  return filteredFeaturedContent.length > 0 ? (
    <div className="homepage__grid__wrapper">
      <h2 dangerouslySetInnerHTML={{ __html: getI18nLabel({ label: 'featuredArticles' }) }} />
      <ul className={classNames('homepage__grid', { 'homepage__grid--cms': editable })}>
        {filteredFeaturedContent.slice(0, numberOfItemsShown).map(item => (
          <li className="homepage__grid-item" key={`homepage-grid-${item.id}`}>
            {editable
              ? (
                <EditOverlay
                  {...item}
                  totalCount={filteredFeaturedContent.length}
                  elementType="featuredContent"
                >
                  <EditorialItem
                    {...item}
                  />
                </EditOverlay>
              )
              : <EditorialItem {...item} />
            }
          </li>
        ))}
      </ul>
      {numberOfItemsShown < filteredFeaturedContent.length ? (
        <button className="homepage__grid__button" onClick={handleMore} type="button">
          {getI18nLabel({label: 'exploreMore'})}
        </button>
      ) : null}
    </div>
  ) : null;
};
HomePageGrid.propTypes = {
  language: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  featuredContent: featuredContentPropTypes.isRequired,
  itemsShown: PropTypes.number.isRequired
};

export default HomePageGrid;
