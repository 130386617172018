import React from 'react';
import PropTypes from "prop-types";
import setI18nLanguage from "../../utils/getI18nLabel";

const PrivateServices = ({language}) => {
  const getI18nLabel = setI18nLanguage(language);

  return (
    <div className="private-services-banner container">
      <h2 className="private-services-banner__title">
        {getI18nLabel({label: 'privateServices'})}
      </h2>
      <div className="private-services-banner__info row">
        <div className="private-services-banner__info__section__private-sales">
          <h3 className="private-services-banner__info__section__title">
            {getI18nLabel({label: 'privateSales'})}
          </h3>
          <p>
            {getI18nLabel({label: 'privateSalesContent'})}
          </p>
          <a href="/private-sales-department">
            {getI18nLabel({label: 'learnMore'})}
          </a>
        </div>
        <div className="private-services-banner__info__section__trusts">
          <h3 className="private-services-banner__info__section__title">
            {getI18nLabel({label: 'professionalAdvisorServices'})}
          </h3>
          <p>
            {getI18nLabel({label: 'professionalAdvisorServicesContent'})}
          </p>
          <a href="/professional-advisor-services">
            {getI18nLabel({label: 'learnMore'})}
          </a>
        </div>
      </div>
    </div>
  );
}

PrivateServices.propTypes = {
  language: PropTypes.string.isRequired
}

export default PrivateServices;
