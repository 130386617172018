import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { selectElement } from './actions';
import setI18nLabel from '../../../utils/getI18nLabel';

const setArray = (elementProps) => elementProps.map(prop => ({
  ...prop,
  totalCount: elementProps.length
}));
const CMSControls = ({
  addType,
  buyNowSaleNumber,
  carouselId,
  children,
  editable,
  editType,
  elementProps,
  id,
  showAdd,
  showEdit
}) => {
  const { language } = useSelector(state => state);
  const getI18nLabel = setI18nLabel(language);
  const selectedElement = Array.isArray(elementProps)
    ? setArray(elementProps)
    : {
      buyNowSaleNumber,
      id: carouselId || id,
      totalCount: Array.isArray(elementProps)
        ? elementProps.length
        : carouselId > 0
          ? elementProps.carouselItems.length
          : 0,
      ...elementProps
    };
  const dispatch = useDispatch();
  const handleClick = ({ target }) => {
    const elementType = target.dataset.type === 'add'
      ? addType
      : editType;
    dispatch(selectElement({
      selectedElement,
      elementType,
      type: target.dataset.type
    }));
  }
  return editable
    ? (
      <div className="add-button-wrapper">
        {showEdit
          ? (
            <button
              className="btn edit-homepage"
              data-type="edit"
              type="button"
              onClick={handleClick}
            >
              {capitalize(getI18nLabel({ label: 'edit' }))}
            </button>
          )
          : null
        }
        {showAdd
          ? (
            <button
              className="btn edit-homepage"
              data-type="add"
              onClick={handleClick}
              type="button"
            >
              {getI18nLabel({ label: 'addItem', args: [getI18nLabel({ label: addType })] })}
            </button>
          )
          : null
        }
        {children}
      </div>
    ) : (
      <Fragment>
        {children}
      </Fragment>
    );
}
CMSControls.defaultProps = {
  buyNowSaleNumber: '',
  carouselId: null,
  elementProps: [],
  id: 0,
  showAdd: true,
  showEdit: true,
  editable: false
}
CMSControls.propTypes = {
  buyNowSaleNumber: PropTypes.string,
  children: PropTypes.element.isRequired,
  editType: PropTypes.string.isRequired,
  addType: PropTypes.string.isRequired,
  carouselId: PropTypes.number,
  elementProps: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]),
  id: PropTypes.number,
  showEdit: PropTypes.bool,
  showAdd: PropTypes.bool,
  editable: PropTypes.bool
};

export default CMSControls;
