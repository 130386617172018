import 'fetch-ponyfill';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PhillipsCloudinary, { getImageDimensions } from './PhillipsCloudinary';

const Loading = ({ transformation }) => (
  <div className="image-loader" style={{ ...getImageDimensions(transformation), zIndex: 10 }}><div className="signal" /></div>
);

Loading.propTypes = {
  transformation: PropTypes.string.isRequired
};

const PhillipsImage = (props) => {
  const [isMounted, setMount] = useState(false);
  useEffect(() => {
    setMount(true);
  }, []);
  const [error, setError] = useState(false);

  const handleError = (event) => {
    setError(true);
    if (props.onError) {
      props.onError(event);
    }
  }
  const styles = props.useTransformation
    ? {
      height: isMounted
        ? 'auto'
        : getImageDimensions(props.transformation).height
    }
    : {
      height: props.height,
      width: props.width
    };
  return (
    <div className={classNames('phillips-image', props.className)} style={styles}>
      {isMounted
        ? error
          ? <img className="phillips-image__error" src="https://content.phillips.com/images/placeholders/item_placeholder.png" alt="Error" />
          : (
            <PhillipsCloudinary
              {...props}
              onError={handleError}
            />
          )
        : <Loading transformation={props.transformation} />
      }
    </div>
  );
}

PhillipsImage.defaultProps = {
  alt: '',
  className: '',
  cloudinary: true,
  constrain: false,
  crop: 'scale',
  curatedView: false,
  height: null,
  hideLoader: false,
  loadType: 'lazy',
  onError: null,
  onLoad: null,
  padX: false,
  padY: false,
  sizeMode: 'fitpadded',
  transformation: 'AuctionLotsView',
  useTransformation: true,
  version: '1',
  width: null
};

PhillipsImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  cloudinary: PropTypes.bool,
  crop: PropTypes.string,
  curatedView: PropTypes.bool,
  onLoad: PropTypes.func,
  onError: PropTypes.func,
  loadType: PropTypes.string,
  hideLoader: PropTypes.bool,
  transformation: PropTypes.string,
  imagePath: PropTypes.string.isRequired,
  height: PropTypes.number,
  constrain: PropTypes.bool,
  padX: PropTypes.bool,
  padY: PropTypes.bool,
  sizeMode: PropTypes.string,
  version: PropTypes.string,
  useTransformation: PropTypes.bool,
  width: PropTypes.number
};

export default PhillipsImage;
