const targetSaleNumberAndLotNumberRange = (targetSaleNumber, lotNumberStart, lotNumberEnd, lot) => {
    const isTargetSaleNumberEqualSaleNumber = targetSaleNumber === lot?.saleNumber;
    const isLotNumberFullInTheRange = Number(lotNumberStart) <= Number(lot?.lotNumberFull) && Number(lot?.lotNumberFull) <= Number(lotNumberEnd);

    if (isTargetSaleNumberEqualSaleNumber && isLotNumberFullInTheRange) {
        return true;
    }

    return false;
}

const lotImgSrc = (props) => {
    let imgsrc = ''

    if (props.saleNumber === 'UK040221') {
        if (
            props.lotNumberFull === '1' ||
            props.lotNumberFull === '2' ||
            props.lotNumberFull === '3' ||
            props.lotNumberFull === '4' ||
            props.lotNumberFull === '5' ||
            props.lotNumberFull === '6' ||
            props.lotNumberFull === '7' ||
            props.lotNumberFull === '8' ||
            props.lotNumberFull === '9' ||
            props.lotNumberFull === '10'
        ) {
            imgsrc = '/images/Ultimate_Irving_Badge.png';
        } else if (
            props.lotNumberFull === '21' ||
            props.lotNumberFull === '22' ||
            props.lotNumberFull === '23' ||
            props.lotNumberFull === '24' ||
            props.lotNumberFull === '25' ||
            props.lotNumberFull === '26' ||
            props.lotNumberFull === '27' ||
            props.lotNumberFull === '28' ||
            props.lotNumberFull === '29' ||
            props.lotNumberFull === '20'
        ) {
            imgsrc = '/images/Ultimate_Badge.png';
        }
    } else if (
        (props.saleNumber === 'HK010321' && (props.lotNumberFull === '41' || props.lotNumberFull === '50')) ||
        (props.saleNumber === 'HK010421' &&
            (props.lotNumberFull === '129' ||
                props.lotNumberFull === '130' ||
                props.lotNumberFull === '131' ||
                props.lotNumberFull === '132' ||
                props.lotNumberFull === '133' ||
                props.lotNumberFull === '134' ||
                props.lotNumberFull === '135' ||
                props.lotNumberFull === '136' ||
                props.lotNumberFull === '137' ||
                props.lotNumberFull === '138' ||
                props.lotNumberFull === '139' ||
                props.lotNumberFull === '140' ||
                props.lotNumberFull === '141' ||
                props.lotNumberFull === '142' ||
                props.lotNumberFull === '143' ||
                props.lotNumberFull === '144' ||
                props.lotNumberFull === '145' ||
                props.lotNumberFull === '146' ||
                props.lotNumberFull === '147' ||
                props.lotNumberFull === '148' ||
                props.lotNumberFull === '149' ||
                props.lotNumberFull === '150' ||
                props.lotNumberFull === '151' ||
                props.lotNumberFull === '152' ||
                props.lotNumberFull === '153' ||
                props.lotNumberFull === '154' ||
                props.lotNumberFull === '155' ||
                props.lotNumberFull === '156' ||
                props.lotNumberFull === '157' ||
                props.lotNumberFull === '158'))
    ) {
        imgsrc = '/images/UltraNeo-Badge.svg';
    } else if (props.saleNumber === 'HK010122') {
        if (
            [...Array(15).keys()]
                .map(i => i + 35)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/UltraNeo-Badge.svg';
        }
    } else if (props.saleNumber === 'HK010222') {
        if (
            [...Array(41).keys()]
                .map(i => i + 263)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/UltraNeo-Badge.svg';
        }
    } else if (props.saleNumber === 'UK010323') {
        if (
            [...Array(26).keys()]
                .map(i => i + 76)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Belilty-Badge.svg';
        }
    } else if (props.saleNumber === 'HK010322') {
        if (
            [...Array(9).keys()]
                .map(i => i + 27)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/UltraNeo-Badge.svg';
        }
    } else if (props.saleNumber === 'UK040122') {
        if (['34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45'].includes(props.lotNumberFull)) {
            imgsrc = '/images/Ultimate.svg';
        } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'].includes(props.lotNumberFull)) {
            imgsrc = '/images/In-Focus-Peter-Beard.svg';
        }
    } else if (props.saleNumber === 'UK040222') {
        if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32'].includes(props.lotNumberFull)) {
            imgsrc = '/images/Cotswolds.svg';
        } else if (['52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64'].includes(props.lotNumberFull)) {
            imgsrc = '/images/Ultimate.svg';
        } else if (['120', '121', '122', '123', '124', '125', '126', '127', '128', '129', '130', '131', '132', '133', '134', '135', '136', '137', '138', '139'].includes(props.lotNumberFull)) {
            imgsrc = '/images/UltimatePink.svg';
        }
    } else if (props.saleNumber === 'NY040123') {
        if (
            [...Array(38).keys()]
                .map(i => i + 203)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Bunnell-Badge.svg';
        } else if (
            [...Array(11).keys()]
                .map(i => i + 31)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Apraxine-Badge.svg';
        } else if (
            [...Array(21).keys()]
                .map(i => i + 283)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Esman-Badge.svg';
        }
    } else if (props.saleNumber === 'UK040123') {
        if (
            [...Array(12).keys()]
                .map(i => i + 10)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Ultimate-UK040123.svg';
        } else if (
            [...Array(39).keys()]
                .map(i => i + 130)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/SpotlightCollection.svg';
        }
    } else if (props.saleNumber === 'NY010423') {
        if (
            [...Array(40).keys()]
                .map(i => i + 157)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Esman-Collection.svg';
        }
    } else if (props.saleNumber === 'UK010423') {
        if (
            [...Array(24).keys()]
                .map(i => i + 64)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Lemann-Collection.svg';
        }
    } else if (props.saleNumber === 'NY010623') {
        if (
            [...Array(7).keys()]
                .map(i => i + 177)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Lemann-Collection.svg';
        } else if (
            [...Array(22).keys()]
                .map(i => i + 93)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Esman-Collection.svg';
        }
    } else if (props.saleNumber === 'NY030423') {
        if (
            [...Array(30).keys()]
                .map(i => i + 1)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Provincetown-Printers-Badge.svg';
        } else if (['83', '84', '180', '185', '186', '187', '190', '265', '266', '267', '268', '276'].includes(props.lotNumberFull)) {
            imgsrc = '/images/Lemann-Collection.svg';
        }
    } else if (props.saleNumber === 'UK040323') {
        if (
            [...Array(14).keys()]
                .map(i => i + 1)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Ultimate.svg';
        } else if (
            [...Array(18).keys()]
                .map(i => i + 85)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Steven-Klein-Badge.svg';
        }
    } else if (props.saleNumber === 'NY030323') {
        if (['38', '40', '57', '83', '85', '89'].includes(props.lotNumberFull)) {
            imgsrc = '/images/Esman-Collection.svg';
        }
    } else if (props.saleNumber === 'NY030223') {
        if (
            [...Array(67).keys()]
                .map(i => i + 1)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Esman-Badge-Yellow.svg';
        }
    } else if (props.saleNumber === 'UK030124') {
        if (
            [...Array(40).keys()]
                .map(i => i + 13)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Editions-Schellmann.svg';
        }
    } else if (props.saleNumber === 'NY030124') {
        if (['11', '12', '13', '22', '29', '46', '49', '60', '61', '62', '64', '65', '66', '69', '70', '71', '73', '74', '80'].includes(props.lotNumberFull)) {
            imgsrc = '/images/Robert-Bechtle-Estate.svg';
        }
    } else if (props.saleNumber === 'NY040323') {
        if (
            [...Array(40).keys()]
                .map(i => i + 161)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/bidwell-badge.svg';
        }
    } else if (props.saleNumber === 'NY040124') {
        if (['317', '318', '319', '320', '321', '322', '323', '324', '325', '326', '327', '328', '329', '330', '331', '332', '333', '334', '335', '336', '337', '338', '339', '340', '341', '342', '343', '344', '345', '346', '347', '348', '349', '350', '351', '352', '353', '354'].includes(props.lotNumberFull)) {
            imgsrc = '/images/FigureAndForm.svg';
        }
    } else if (targetSaleNumberAndLotNumberRange("UK040124", 1, 10, props)) {
        imgsrc = '/images/ultimate.svg';
    } else if (targetSaleNumberAndLotNumberRange("UK040124", 121, 170, props)) {
        imgsrc = '/images/de_pury_collection_badge.svg';
    } else if (props.saleNumber === 'HK070124') {
        if (
            [...Array(9).keys()]
                .map(i => i + 10)
                .map(String)
                .includes(props.lotNumberFull) ||
            [...Array(9).keys()]
                .map(i => i + 64)
                .map(String)
                .includes(props.lotNumberFull)
        ) {
            imgsrc = '/images/Steven-Klein-Badge.svg';
        }
    } else if (props.saleNumber === 'NY030524') {
        if (['2', '3', '4', '13', '14', '15', '17', '24', '33', '36', '38', '42', '43', '44', '48', '49', '57', '60', '63', '64', '65', '66', '68', '69', '70', '73', '80', '81', '85', '86', '94', '96', '97', '98', '99', '111', '124', '125', '129', '132', '135', '144', '155', '157', '158', '165', '166', '179', '181', '184', '190'].includes(props.lotNumberFull)) {
            imgsrc = '/images/LESPrintshop.svg';
        }
    } else if (props.saleNumber === 'NY030624') {
        if (['76', '126', '129', '131', '132', '142', '143', '144', '145', '146', '147', '162', '163', '164', '165', '166', '175', '176', '178', '185', '201'].includes(props.lotNumberFull)) {
            imgsrc = '/images/Rosenberg-Collection-Badge.svg';
        }
        if (['10', '11', '12', '13', '148', '149', '150', '151', '152', '153', '154', '155', '156', '157', '158', '159', '160', '161'].includes(props.lotNumberFull)) {
            imgsrc = '/images/Friedman-Estate-Badge.svg';
        }
    } else if (props.saleNumber === 'HK080524') {
        if ([...Array(6).keys()]
            .map(i => i + 896)
            .map(String)
            .includes(props.lotNumberFull)) {
            imgsrc = '/images/The-Lavish-Attic.svg';
        }
    } else if (props.saleNumber === 'NY080424') {
        if ([...Array(25).keys()]
            .map(i => i + 81)
            .map(String)
            .includes(props.lotNumberFull)) {
            imgsrc = '/images/TIMEFORART.svg';
        }
    }

    return imgsrc
}

export default lotImgSrc;