import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import isNull from 'lodash/isNull';
import has from 'lodash/fp/has';
import PropTypes from 'prop-types';
import cloudinaryConfig from './cloudinaryConfig';

const cloudinaryTransformations = {
  AuctionHighlightsGalleryModal: 'Website_AuctionHighlightsGalleryModal',
  AuctionInlinePopup: 'Website_AuctionPageLot',
  AuctionLotsView: 'Website_AuctionPageLot',
  AuctionThumb: 'Website_Auction_Thumb_dev4',
  EditorialHubFeaturedMain: 'editorial_image_featured-main',
  EditorialHubFullWidth: 'editorial_image_full-width',
  EditorialHub: 'editorial_image',
  EditorialHubVideoThumb: 'editorial_hub_feed_video_dev3',
  HomePageCarousel: 'Website_HomePageCarousel',
  LotDetailMainImage: 'Website_LotDetailMainImage',
  LotDetailZoomImage: 'Website_LotDetailZoomImage',
  LotDetailAlternateThumbs: 'Website_LotDetailThumb',
  LotDetailAlternateThumbsMainImage: 'Website_LotDetailThumb',
  LotDetailAltImageFullSize: 'Website_LotDetailMainImage',
  LotDetailZoomImageAlt: 'Website_LotDetailZoomImage',
  LotGridFixed: 'Lot_Grid_Fixed_3',
  // Curated Auction Transformations
  SingleCell: 'Website_CuratedAuction_SingleCell_NoPad_dev2',
  // Curated Auction Containers
  ContainerTworowsTwocolumnsFeatureLeft: 'Website_CuratedAuction_TwoColumns_dev2',
  ContainerTworowsTwocolumnsFeatureRight: 'Website_CuratedAuction_TwoColumns_dev2',
  ContainerTworowsTwocolumnsFeatureCenter: 'Website_CuratedAuction_TwoColumns_dev2',
  ContainerTworowsTwocolumnsTopRight: 'Website_CuratedAuction_SingleCell_NoPad_dev2',
  ContainerTworowsTwocolumnsBtmRight: 'Website_CuratedAuction_SingleCell_NoPad_dev2',
  ContainerTworowsTwocolumnsTopLeft: 'Website_CuratedAuction_SingleCell_NoPad_dev2',
  ContainerTworowsTwocolumnsBtmLeft: 'Website_CuratedAuction_SingleCell_NoPad_dev2',
  ContainerTworowsThreecolumnsTopLeft: 'Website_CuratedAuction_SingleCell_NoPad',
  ContainerTworowsThreecolumnsBottomLeft: 'Website_CuratedAuction_SingleCell_NoPad',
  ContainerTworowsThreecolumnsTopMiddle: 'Website_CuratedAuction_SingleCell_NoPad',
  ContainerTworowsThreecolumnsBottomMiddle: 'Website_CuratedAuction_SingleCell',
  ContainerTworowsThreecolumnsTopRight: 'Website_CuratedAuction_SingleCell_NoPad',
  ContainerTworowsThreecolumnsBottomRight: 'Website_CuratedAuction_SingleCell_NoPad',
  ContainerTworowsThreecolumnsFeatureLeft: 'Website_CuratedAuction_TwoRows',
  ContainerTworowsThreecolumnsFeatureMiddle: 'Website_CuratedAuction_TwoRows',
  ContainerTworowsThreecolumnsFeatureRight: 'Website_CuratedAuction_TwoRows',
  OnerowTwocolumnsLeft: 'Website_CuratedAuction_TwoColumnsOneRowNoPad',
  OnerowTwocolumnsRight: 'Website_CuratedAuction_TwoColumnsOneRowNoPad',
  TwoColumnsOneRowNoPad: 'Website_CuratedAuction_TwoColumnsOneRowNoPad',
  TwoColumnsOneRow: 'Website_CuratedAuction_TwoColumnsOneRowNoPad',
  ThreeColumns: 'Website_CuratedAuction_ThreeColumns',
  ThreeColumnsOneRow: 'Website_CuratedAuction_ThreeColumns',
  TwoRowsTwoColumns: 'Website_CuratedAuction_TwoRowsTwoColumns',
  TwoRows: 'Website_CuratedAuction_TwoRows',
  TwoLotsOneRow: 'Website_CuratedAuction_TwoLotsOneRow'
};

export const getCloudinaryTransformation = transformation => {
  if (typeof cloudinaryTransformations[transformation] === 'undefined') {
    console.error(`Cloudinary Transformation ${transformation} does not exist`);
    return 'error';
  }
  return cloudinaryTransformations[transformation];
};
export const getImageDimensions = transformation => {
  const noDim = {
    width: 'auto',
    height: 'auto',
    aspect: null
  };
  const dimensions = {
    editorial_image: {
      width: '250.5px',
      height: '167px',
      aspect: 250.5 / 167
    },
    'editorial_image_featured-main': {
      width: '790.5px',
      height: '527px',
      aspect: 790.5 / 527
    },
    'editorial_image_full-width': {
      width: '649.2px',
      height: '432.8px',
      aspect: 649.2 / 432.8
    },
    Website_AuctionPageLot: {
      width: 230.5,
      height: 294.5,
      aspect: 0.782682512733447
    },
    Website_AuctionHighlightsGalleryModal: {
      width: 771.5,
      height: 428.6,
      aspect: 771.5 / 428.6
    }
  };
  return dimensions[getCloudinaryTransformation(transformation)] || noDim;
};
const getImageSource = props => {
  const { crop, useTransformation, imagePath, transformation, version, width, height } = props;
  return useTransformation
    ? cloudinaryConfig.url(imagePath, {
        transformation: getCloudinaryTransformation(transformation),
        version: version
      })
    : cloudinaryConfig.url(imagePath, {
        width: width,
        height: height,
        crop: crop,
        version: version
      });
};
const PhillipsCloudinary = props => {
  const ref = useRef(null);
  const [loaded, setLoad] = useState(false);
  const handleLoad = e => {
    const event = has('target')(e) ? e : { target: e, type: 'load' };
    if (props.onLoad) {
      props.onLoad(event);
    }
    if (!loaded) {
      setLoad(true);
    }
  };
  useEffect(() => {
    const domNode = ref.current;
    const effectHandleLoad = handleLoad;
    const onComplete = event => {
      if (!loaded) {
        if (props.onLoad) {
          props.onLoad(event);
        }
        setLoad(true);
      }
    };
    if (!isNull(domNode)) {
      domNode.addEventListener('complete', onComplete);
      if (ref.current.complete && !loaded) {
        effectHandleLoad({ target: ref.current, type: 'load' });
        setLoad(true);
      }
    }
    return () => {
      domNode.removeEventListener('complete', onComplete);
    };
  }, []);
  useEffect(() => {
    setLoad(false);
  }, [props.imagePath]);

  const imageDimensions = loaded
    ? {}
    : props.useTransformation
    ? getImageDimensions(props.transformation)
    : {
        width: props.width,
        height: props.height
      };
  const src = getImageSource({ ...props, ...imageDimensions });
  return (
    <img
      className={classNames('phillips-image__image', { 'phillips-image__image--loading': !loaded })}
      style={{ ...imageDimensions }}
      alt={props.alt}
      data-pin-url={getImageSource(props)}
      loading={props.loadType}
      onError={props.onError}
      onLoad={handleLoad}
      src={src}
      title={props.alt}
      ref={ref}
    />
  );
};

PhillipsCloudinary.defaultProps = {
  alt: 'no alt text provided',
  width: null,
  height: null,
  loadType: 'lazy',
  onLoad: null,
  crop: 'scale',
  version: '1',
  transformation: 'AuctionLotsView',
  useTransformation: true
};

PhillipsCloudinary.propTypes = {
  alt: PropTypes.string,
  loadType: PropTypes.string,
  imagePath: PropTypes.string.isRequired,
  version: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  crop: PropTypes.string,
  transformation: PropTypes.string,
  useTransformation: PropTypes.bool,
  onLoad: PropTypes.func,
  onError: PropTypes.func.isRequired
};

export default PhillipsCloudinary;
