import React from 'react';
import PropTypes from 'prop-types';
import replace from 'lodash/replace';
import startCase from 'lodash/startCase';
import Link from 'redux-first-router-link';
import PhillipsImage from '../PhillipsImage/PhillipsImage';



const PhillipsLotImage = (props) => {
  const { language } = props;
  const displayType = (
    props.showCuratedView
    && props.auctionLotDisplayTypeName !== 'single-cell'
  )
    ? props.auctionLotDisplayTypeName
    : props.imageTransformation;
  const imageTransformation = replace(startCase(displayType), /\s/g, '');
  const makerName = (props.makerName.length > 0)
    ? props.makerName
    : 'NoArtist';
  const actionType = makerName.toUpperCase() === 'NOARTIST'
    ? 'ROUTES_NO_MAKER'
    : props.language === 'en'
      ? 'ROUTES_DEFAULT'
      : 'ROUTES_LANGUAGE';
  const payload = makerName.toUpperCase() === 'NOARTIST'
    ? {
      saleNumber: props.saleNumber,
      lotNumberFull: props.lotNumberFull.trim()
    }
    : props.language === 'en'
      ? {
        makerName: makerName,
        saleNumber: props.saleNumber,
        lotNumberFull: props.lotNumberFull.trim()
      }
      : {
        language,
        makerName: makerName,
        saleNumber: props.saleNumber,
        lotNumberFull: props.lotNumberFull.trim()
      };
  const lotUrl = {
    type: actionType,
    payload
  }
  return (
    <div className="phillips-lot__image">
      {props.hasRouter
        ? (
          <Link
            to={lotUrl}
          >
            <PhillipsImage
              alt={`${props.makerName} - ${props.description}`}
              height={props.height}
              imagePath={props.imagePath}
              loadType={props.loadType}
              transformation={imageTransformation}
              version={props.imageVersion}
              width={props.width}
            />
          </Link>
        )
        : props.detailLink.length === 0 || props.detailLink === "#"
          ? (
            <PhillipsImage
              alt={`${props.makerName} - ${props.description}`}
              imagePath={props.imagePath}
              height={props.height}
              loadType={props.loadType}
              transformation={imageTransformation}
              version={props.imageVersion}
              width={props.width}
            />
          )
          : (
            <a href={props.detailLink} className="detail-link">
              <PhillipsImage
                alt={`${props.makerName} - ${props.description}`}
                imagePath={props.imagePath}
                height={props.height}
                loadType={props.loadType}
                transformation={imageTransformation}
                version={props.imageVersion}
                width={props.width}
              />
            </a>
          )
      }
    </div>
  );
};

PhillipsLotImage.defaultProps = {
  auctionLotDisplayTypeName: null,
  description: '',
  detailLink: '#',
  hasRouter: false,
  height: null,
  imagePath: '/images/item_placeholder.png',
  imageTransformation: 'SingleCell',
  imageVersion: '1',
  loadType: 'lazy',
  lotNumberFull: '',
  makerName: '',
  saleNumber: '',
  showCuratedView: false,
  useCloudinary: true,
  width: null
};

PhillipsLotImage.propTypes = {
  auctionLotDisplayTypeName: PropTypes.string,
  description: PropTypes.string,
  detailLink: PropTypes.string,
  hasRouter: PropTypes.bool,
  height: PropTypes.number,
  imagePath: PropTypes.string,
  imageTransformation: PropTypes.string,
  imageVersion: PropTypes.string,
  language: PropTypes.string.isRequired,
  loadType: PropTypes.string,
  lotNumberFull: PropTypes.string,
  makerName: PropTypes.string,
  saleNumber: PropTypes.string,
  showCuratedView: PropTypes.bool,
  useCloudinary: PropTypes.bool,
  width: PropTypes.number
};

export default PhillipsLotImage;
