/* eslint-disable no-confusing-arrow */
import { createSelector } from 'reselect';
import PhillipsLot from '../PhillipsLot/PhillipsLot';
import PhillipsMaker from '../PhillipsMaker/PhillipsMaker';
import EditOverlay from '../CMS/HomePage/EditOverlay';
import getCarouselType from '../PhillipsCarousel/utils/getCarouselType';
import { setI18nLinkLanguage } from '../../utils/getI18nLabel';

const getCarouselData = (state, props) => ({ ...props, language: state.language });
const makeCarouselChildren = () => createSelector(
  [
    getCarouselData
  ],
  (carouselData) => {
    const { carouselItems, carouselId, carouselTypeId, editable, language } = carouselData;
    const carouselType = getCarouselType(carouselTypeId);
    const getI18nLink = setI18nLinkLanguage(language);
    switch (carouselType) {
      case 'lot':
        return {
          ...carouselData,
          children: carouselItems.map(lot => editable
            ? (
              <EditOverlay
                {...lot}
                carouselId={carouselId}
                elementType={carouselType}
                key={`carousel-${carouselId}-item-${lot.carouselItemId}`}
                totalCount={carouselItems.length}
                language={language}
              >
                <PhillipsLot
                  {...lot}
                  detailLink={getI18nLink(lot.detailLink)}
                  buyNowsaleNumber={carouselData.buyNowsaleNumber}
                  carouselId={carouselId}
                  editable={editable}
                  imageTransformation="HomePageCarousel"
                  key={`carousel-${carouselId}-item-${lot.carouselItemId}`}
                  language={language}
                  lotListDisabled
                  showLotNumber={false}
                  totalCount={carouselItems.length}
                  transformation="HomePageCarousel"
                />
              </EditOverlay>
            )
            : (
              <PhillipsLot
                {...lot}
                detailLink={getI18nLink(lot.detailLink)}
                buyNowsaleNumber={carouselData.buyNowsaleNumber}
                carouselId={carouselId}
                editable={editable}
                imageTransformation="HomePageCarousel"
                key={`carousel-${carouselId}-item-${lot.carouselItemId}`}
                language={language}
                lotListDisabled
                showLotNumber={false}
                totalCount={carouselItems.length}
                transformation="HomePageCarousel"
              />
            )
          )
        };
      case 'maker':
        return {
          ...carouselData,
          children: carouselItems.map(maker => editable
            ? (
              <EditOverlay
                {...maker}
                carouselId={carouselId}
                elementType={carouselType}
                key={`carousel-${carouselId}-item-${maker.carouselItemId}`}
                totalCount={carouselItems.length}
                language={language}
              >
                <PhillipsMaker
                  language={language}
                  {...maker}
                  key={`carousel-${carouselId}-item-${maker.carouselItemId}`}
                  editable={editable}
                  carouselId={carouselId}
                  imageTransformation="HomePageCarousel"
                  totalCount={carouselItems.length}
                />
              </EditOverlay>
            )
            : (
              <PhillipsMaker
                language={language}
                {...maker}
                key={`carousel-${carouselId}-item-${maker.carouselItemId}`}
                editable={editable}
                carouselId={carouselId}
                imageTransformation="HomePageCarousel"
                totalCount={carouselItems.length}
              />
            )
          )
        };
      default:
        return {
          name: 'No Type set',
          children: []
        };
    }
  }
);

export { makeCarouselChildren };
