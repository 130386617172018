import React from 'react';
import PropTypes from 'prop-types';
import CMSControls from './CMSControls';
import EditOverlay from './EditOverlay';
import { featuredVideosPropTypes, defaultVideo } from '../../HomePage/proptypes';
import PhillipsCarousel from '../../PhillipsCarousel/PhillipsCarousel';

const VideoForm = ({ data, description, title, language }) => (
  <section id="video-form">
    <span>
      <h2>{title}</h2>
        &nbsp;&nbsp;&ndash;&nbsp;&nbsp;
      <p>{description}</p>
    </span>
    <CMSControls
      editable
      elementProps={{
        ...defaultVideo,
        totalCount: data.filter(v => v.language === language.toUpperCase()).length,
        language: language.toUpperCase()
      }}
      addType="video"
      showAdd
      showEdit={false}
    >
      <PhillipsCarousel
        classNames="cms-video-form"
      >
        {data
          .filter(v => v.language === language.toUpperCase())
          .map(video => (
            <EditOverlay
              {...video}
              totalCount={data.filter(v => v.language === language.toUpperCase()).length}
              key={`homepage-video-${video.id}`}
              elementType="video"
            >
              <div className="cms-video">
                <img
                  alt={video.videoUrl}
                  src={video.thumbnail}
                />
                <div>
                  <h2>{video.thumbnailCaption}</h2>
                </div>
              </div>
            </EditOverlay>
          ))}
      </PhillipsCarousel>
    </CMSControls>
  </section>
);
VideoForm.defaultProps = {
  data: [],
  description: 'Add, edit, and order videos to display on homepage',
  language: 'en',
  title: 'Video Carousel (CMS Only)'
};
VideoForm.propTypes = {
  data: featuredVideosPropTypes,
  description: PropTypes.string,
  language: PropTypes.string,
  title: PropTypes.string
};
export default VideoForm;
