import React, { useState } from 'react';
import PropTypes from "prop-types";
import { featuredVideosPropTypes } from './proptypes';
import setI18nLanguage from "../../utils/getI18nLabel";

const HomepageVideo = ({ language, featuredVideos }) => {
  const getI18nLabel = setI18nLanguage(language);
  const [cI, setIndex] = useState(0);
  const handleChange = e => setIndex(parseInt(e.currentTarget.value, 10));

  const filteredFeaturedVideos = featuredVideos
    .filter((item) => (item.language === language.toUpperCase()) && item.active)
    .sort((a, b) => a.displayOrder - b.displayOrder);
  return filteredFeaturedVideos.length > 0 ? (
    <div className="homepage__video">
      <div className="homepage__video__wrapper">
        <iframe
          title={filteredFeaturedVideos[cI].videoUrl}
          src={filteredFeaturedVideos[cI].videoUrl}
          frameBorder="0"
          allowFullScreen
        />
      </div>
      <div className="homepage__video__description">
        <div
          className="homepage__video__title col-sm-2 col-md-4"
          dangerouslySetInnerHTML={{ __html: filteredFeaturedVideos[cI].saleInfo }}
        />
        <div
          className="homepage__video__caption col-sm-2 col-md-4"
          dangerouslySetInnerHTML={{ __html: filteredFeaturedVideos[cI].htmlCaption }}
        />
      </div>
      <ul className="homepage__video__thumbnails">
        {filteredFeaturedVideos.slice(0, 3).map((video, i) => (
          <li
            className="homepage__video__thumbnail-wrapper"
            key={`featuredVideo-${video.id}`}
            title={`Thumbnail for Vimeo ${video.videoUrl}`}
            style={{
              backgroundImage: `url(${video.thumbnail})`
            }}
          >
            <button
              className="homepage__video__thumbnail"
              value={i}
              onClick={handleChange}
              type="button"
            >
              <h2>
                {(video.id === filteredFeaturedVideos[cI].id)
                  ? getI18nLabel({ label: 'nowPlaying' })
                  : video.thumbnailCaption
                }
              </h2>
            </button>
          </li>
        ))}
        <li className="homepage__video__more">
          <a href="/editorial/feed/filter/contentType%3DVideo" title={getI18nLabel({ label: 'moreVideos' })}>
            {getI18nLabel({ label: 'moreVideos' })}
          </a>
        </li>
      </ul>
    </div>
  ) : null;
}

HomepageVideo.propTypes = {
  editable: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  featuredVideos: featuredVideosPropTypes.isRequired
};

export default HomepageVideo;
