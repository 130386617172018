import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import BuyNowCarousel from './BuyNow.container';
import HomePageCarousel from './HomePageCarousel.container';
import HomePageHero from './HomePageHero.container';
import HomePageGrid from './HomePageGrid';
import LowerBannerImg from './LowerBanner';
import MarketPlace from './MarketPlace';
import PhillipsVideo from './HomePageVideo';
import * as homePagePropTypes from './proptypes';
import bindUserModel from '../PhillipsUser/bindUserModel';
import carouselPropTypes, { defaultProps } from '../PhillipsCarousel/proptypes';
import CMSControls from '../CMS/HomePage/CMSControls';
import SellWithUs from '../SellWithUs/SellWithUs';
import DropshopBanner from '../Consignments/DropshopBanner';
import DisplayComponent from '../components/displaycomponent';
import { fetchUserCarousel, removeUserCarousel } from '../PhillipsUser/actions';
import getAmWidget from '../BidButtons/Widget/getAmWidget';
import getPhillipsBackboneProperty from '../utils/getPhillipsBackboneProperty';
import PrivateServices from '../PrivateServices/PrivateServices.container';
import VideoForm from '../CMS/HomePage/VideoForm';
import getCarouselType from '../PhillipsCarousel/utils/getCarouselType';
import { getCurrentLanguage } from '../../utils/languageManagement';
import setI18nLanguage, { getI18nTitle, setI18nLinkLanguage } from '../../utils/getI18nLabel';
import breakpoints from '../utils/breakpoints';

const HomePage = ({ title, autoplay, interval, editable }) => {
  const [pageTitle, setPageTitle] = useState(title);
  const phillipsEvents = useRef(null);
  const state = useSelector(store => store);
  const dispatch = useDispatch();
  const { elements, language, location } = state;
  // csuarez 20201006 I added this here because the mapstatetoprops file was removed and these properties were not being mapped anywhere
  const {
    carousels,
    featuredContent,
    featuredSlider,
    featuredVideos,
    lowerBanner,
    marketPlaceCarousels,
    userCarousel
  } = elements;
  const buyNowCarousel = editable
    ? find(carousels, carousel => carousel.buyNowSaleNumber.length > 0) || defaultProps
    : find(carousels, carousel => carousel.buyNowSaleNumber.length > 0);
  const otherCarousels = carousels
    .filter(carousel => isNull(carousel.buyNowSaleNumber) || carousel.buyNowSaleNumber.length === 0)
    .sort((a, b) => a.displayOrder - b.displayOrder);
  // first carousel that is not Perpetual (lots or makers);
  const carouselOne = editable ? otherCarousels[0] || defaultProps : otherCarousels[0] || null;
  // second carousel that is not Perpetual (lots or makers).  Can be undefined
  const carouselTwo = editable ? otherCarousels[1] || defaultProps : otherCarousels[1] || null;
  useEffect(() => {
    const handleUpdate = updateData => {
      // setI18nPageTitle(title, setPageTitle, updateData.payload.language);
      setPageTitle(
        `Phillips Auction | ${getI18nTitle(updateData.payload.language)({
          title: 'homepage'
        })}`
      );
      dispatch(updateData);
    };
    getPhillipsBackboneProperty('Events')
      .then(res => {
        phillipsEvents.current = res;
        phillipsEvents.current.on('update', handleUpdate);
        const cookieLanguage = getCurrentLanguage();
        const query = location?.query || {};
        if (language !== cookieLanguage) {
          if (cookieLanguage === 'en') {
            dispatch({
              type: 'ROUTES_LANGUAGE',
              query,
              payload: {
                language: language
              }
            });
          }
          if (cookieLanguage === 'zh') {
            dispatch({
              type: 'ROUTES_LANGUAGE',
              query,
              payload: {
                language: cookieLanguage
              }
            });
          }
        }
        // setI18nPageTitle(title, setPageTitle, language);
      })
      .catch(err => console.error('There was an error fetching phillips.Events: ', err));
  }, []);
  useEffect(() => {
    if (marketPlaceCarousels.length > 0 && !editable) {
      getAmWidget()
        .then(widget => {
          const { AmWidget } = widget;
          const { carouselItems } = marketPlaceCarousels[0];
          const { auctionMobilityAuctionRowId } = carouselItems[0];

          const widgetRef = new AmWidget({
            auctionId: auctionMobilityAuctionRowId,
            url: window.localStorage.getItem('amApiUrl'),
            wrapperPrefix: `am-target-${auctionMobilityAuctionRowId}`,
            layout: 'grid',
            lots: carouselItems
              .map(lot => lot.auctionMobilityLotRowId)
              .filter(rowId => !isNull(rowId) && rowId.length > 0 && rowId !== '0')
          });
          widgetRef.init();
          // eslint-disable-next-line camelcase
          widgetRef.setWidgetHandler('system', ({ websocket_state }) => {
            // console.log('Auction Page websocket state: ', websocket_state);
          });
        })
        .catch(error => {
          console.error('Error: ', error);
        });
    }
  }, []);
  useEffect(() => {
    if (!editable) {
      getPhillipsBackboneProperty('user').then(userModel => {
        bindUserModel(userModel, dispatch);
        userModel.on('loggedIn', loggedInUser => {
          dispatch(fetchUserCarousel(loggedInUser));
        });
        userModel.on('logout', () => {
          dispatch(removeUserCarousel());
        });
        if (userModel.loggedIn) {
          userModel.fetchUserDetails();
        } else {
          userModel.on('loggedIn', userModel.fetchUserDetails);
        }
      });
    }
  }, [dispatch, editable]);
  const getI18nLabel = setI18nLanguage(language);
  const getI18nLink = setI18nLinkLanguage(language);
  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <section
        className={classNames('homepage', { 'homepage--cms': editable, hongkong: language === 'zh' })}
        id="homepage"
      >
        <HomePageHero
          editable={editable}
          interval={interval}
          autoplay={autoplay}
          language={language}
          featuredSlider={featuredSlider}
        />
        <div className="body-content grid">
          {!isNull(userCarousel) && !isNull(userCarousel.carouselItems) && userCarousel.carouselItems.length > 0 ? (
            <div className="container">
              <HomePageCarousel {...userCarousel} editable={editable} carouselTypeId={1} />
            </div>
          ) : null}
          {!editable && marketPlaceCarousels.length > 0 ? (
            <div className="container">
              <MarketPlace
                key={marketPlaceCarousels[0].carouselTitle}
                carouselType="lot"
                {...marketPlaceCarousels[0]}
                editable={editable}
                language={language}
                getI18nLabel={getI18nLabel}
                getI18nLink={getI18nLink}
              />
            </div>
          ) : null}
          {!isEmpty(featuredVideos) ? (
            <PhillipsVideo editable={editable} language={language} featuredVideos={featuredVideos} />
          ) : null}
          {editable ? (
            <VideoForm
              language={language}
              label={getI18nLabel({ label: 'video' })}
              description={getI18nLabel({ label: 'videoCarouselCopy' })}
              data={featuredVideos}
              title={getI18nLabel({ label: 'videoCarouselLabel' })}
            />
          ) : null}

          <DropshopBanner
            bannerId="Bottom"
            href="https://dropshop.phillips.com"
          >
            <h3>
              Exclusive Drops
              <br />
              By Creators. For Collectors.
            </h3>

            <a href="https://dropshop.phillips.com" className="dropshop-button">Discover</a>
          </DropshopBanner>

          <div className="container">
            {!isNull(carouselOne) ? (
              <CMSControls
                carouselId={carouselOne.carouselId}
                editable={editable}
                elementProps={carouselOne}
                editType="carousel"
                addType={getCarouselType(carouselOne.carouselTypeId)}
                showEdit
                showAdd
              >
                <HomePageCarousel editable={editable} {...carouselOne} />
              </CMSControls>
            ) : null}
            <CMSControls
              editable={editable}
              elementProps={{
                ...homePagePropTypes.defaultContent,
                language: language.toUpperCase(),
                totalCount: featuredContent.filter(c => c.language === language.toUpperCase()).length
              }}
              addType="featuredContent"
              showAdd
              showEdit={false}
            >
              <HomePageGrid
                language={language}
                editable={editable}
                featuredContent={featuredContent}
                itemsShown={6}
                childCount={featuredContent.length}
              />
            </CMSControls>
          </div>
          {editable || buyNowCarousel ? (
            <CMSControls
              carouselId={buyNowCarousel?.carouselId || 0}
              editable={editable}
              elementProps={buyNowCarousel}
              addType={getCarouselType(buyNowCarousel?.carouselId)}
              editType="carousel"
              showAdd
              showEdit
            >
              <BuyNowCarousel {...buyNowCarousel} language={language} carouselTypeId={1} editable={editable} />
            </CMSControls>
          ) : null}
          {!editable ? <PrivateServices language={language} /> : null}

          <SellWithUs language={language} href={getI18nLink('/sell')} />

          {(!isNull(carouselTwo) && !isNull(carouselOne)) || editable ? (
            <div className="body-content">
              <div className="container">
                <CMSControls
                  carouselId={carouselTwo.carouselId}
                  editable={editable}
                  elementProps={carouselTwo}
                  editType="carousel"
                  addType={getCarouselType(carouselTwo.carouselTypeId)}
                  showEdit
                  showAdd
                >
                  <HomePageCarousel editable={editable} {...carouselTwo} />
                </CMSControls>
              </div>
            </div>
          ) : null}
          {/* <DisplayComponent if={editable || (!isNull(lowerBanner) && !isUndefined(lowerBanner))}>
            <CMSControls editable={editable} elementProps={lowerBanner} editType="lowerBanner">
              <LowerBannerImg {...lowerBanner} editable={editable} />
            </CMSControls>
          </DisplayComponent> */}
        </div>
      </section>

    </Fragment>
  );
};

HomePage.defaultProps = {
  title: 'Phillips Auction | Art, Design, Watches, and Jewels',
  autoplay: false,
  buyNowCarousel: null,
  carouselOne: null,
  carouselTwo: null,
  featuredContent: [],
  featuredSlider: [],
  featuredVideos: [],
  interval: 5000,
  lowerBanner: null,
  marketPlaceCarousels: [],
  userCarousel: null
};

HomePage.propTypes = {
  title: PropTypes.string,
  autoplay: PropTypes.bool,
  interval: PropTypes.number,
  buyNowCarousel: PropTypes.shape(carouselPropTypes),
  carouselOne: PropTypes.shape(carouselPropTypes),
  carouselTwo: PropTypes.shape(carouselPropTypes),
  editable: PropTypes.bool.isRequired,
  featuredContent: homePagePropTypes.featuredContentPropTypes,
  featuredSlider: homePagePropTypes.featuredSliderPropTypes,
  featuredVideos: homePagePropTypes.featuredVideosPropTypes,
  lowerBanner: PropTypes.shape(homePagePropTypes.lowerBannerPropTypes),
  marketPlaceCarousels: PropTypes.arrayOf(PropTypes.shape(carouselPropTypes)),
  userCarousel: PropTypes.shape(carouselPropTypes)
};

export default HomePage;
