import React, { Fragment } from 'react';
import PhillipsCarousel from '../PhillipsCarousel/PhillipsCarousel';
import PhillipsLot from '../PhillipsLot/PhillipsLot';
import carouselPropTypes, { defaultProps } from '../PhillipsCarousel/proptypes';

const getMarketPlaceDescription = (title, getI18nLabel, getI18nLink) => `<div class="homepage__marketplace__description"><h2 class="phillips-carousel__title homepage__marketplace__title">${title}</h2>
<p class="homepage__marketplace__copy">${getI18nLabel({ label: 'galleryOneDescription' })}</p></div>
<a class="homepage__marketplace__cta homepage__marketplace__cta--button homepage__marketplace__cta--tablet" href="${getI18nLink('/galleryone?redirectToAuction=true')}" alt="Go to Gallery One">${getI18nLabel({ label: 'enterGalleryOne' })}</a>
`;

const MarketPlace = props => (
  <Fragment>
    <PhillipsCarousel
      carouselDesc={getMarketPlaceDescription("Gallery One", props.getI18nLabel, props.getI18nLink)}
      classNames="homepage__marketplace"
      sizes={{ xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
    >
      {props.carouselItems.map(item => (
        <PhillipsLot
          {...item}
          imageTransformation="HomePageCarousel"
          key={`marketplace-carousel-${item.objectNumber}`}
          lotListDisabled
          showLotNumber={false}
          transformation="HomePageCarousel"
          widgetEnabled
          disableFetchRowId
          language={props.language}
          getI18nLabel={props.getI18nLabel}
        />
      ))}
    </PhillipsCarousel>
    <a className="homepage__marketplace__cta homepage__marketplace__cta--button homepage__marketplace__cta--mobile" href={props.getI18nLink("/galleryone?redirectToAuction=true")} alt="Go to Gallery One">
      {props.getI18nLabel({ label: 'enterGalleryOne' })}
    </a>
  </Fragment>
);

MarketPlace.defaultProps = defaultProps;
MarketPlace.propTypes = carouselPropTypes;
export default MarketPlace;
