import React from 'react';
import PropTypes from "prop-types";
import setI18nLanguage from "../../utils/getI18nLabel";
import sendAnalytics from '../../utils/sendAnalytics';

const SellWithUs = ({ language, href }) => {
    const getI18nLabel = setI18nLanguage(language);

    return (
        <div className="sell-with-us-banner container">
            <div className="sell-with-us-banner__info row">

                <div className="sell-with-us-banner__info__section row">

                    <img
                        className="mobile-image-container"
                        alt="Consign today"
                        src="https://content.phillips.com/HomePage/sell_with_us_gallery_mobile.png"
                    />
                    <div className="caption-container">
                        <p className='label'>{getI18nLabel({ label: 'consignToday' })}</p>
                        <h2 className="title">{getI18nLabel({ label: 'sellWithUs' })}</h2>
                        <p className='description'>{getI18nLabel({ label: 'sellWithUsCopy' })}</p>
                        <a
                            className="submit"
                            href={href}
                            onClick={() => {
                                sendAnalytics({
                                    eventCategory: 'Consignment Click',
                                    eventAction: `Clicked Consignment Promotion, Homepage Banner: Bottom`,
                                    eventLabel: 'www.phillips.com'
                                });
                            }}>
                            {getI18nLabel({ label: 'submitNow' })}
                        </a>

                    </div>
                    <div className="image-container">
                        <img
                            alt="Consign today"
                            src="https://content.phillips.com/HomePage/sell_with_us_gallery.png"
                        />
                    </div>

                </div>

                <div className="sell-with-us-banner__info__section row">

                    <img
                        className="mobile-image-center"
                        alt="Consign today"
                        src="https://content.phillips.com/HomePage/sell_with_us_phone_mobile.png"
                    />
                    <div className="caption-container">
                        <p className='label'>{getI18nLabel({ label: 'downloadThePhillipsApp' })}</p>
                        <h2 className="title">
                            {getI18nLabel({ label: 'bidAnywhere' })}
                        </h2>
                        <p className='description'>{getI18nLabel({ label: 'bidAnywhereContent' })}</p>
                        <a
                            data-segment="App"
                            style={{ display: 'inline', marginRight: '1em' }}
                            href="https://apps.apple.com/us/app/phillips/id397496674"
                        >
                            <img
                                alt="Download the Phillips App on the App Store"
                                className="appstore-badge"
                                style={{ width: '105px' }}
                                src="https://content.phillips.com/HomePage/appstore_badge.svg"
                            />
                        </a>
                        <a
                            data-segment="App"
                            style={{ display: 'inline' }}
                            href="https://play.google.com/store/apps/details?id=com.auctionmobility.auctions.phillips&pli=1"
                        >
                            <img
                                alt="Download the Phillips App on Google Play"
                                className="appstore-badge"
                                style={{ width: '105px' }}
                                src="https://content.phillips.com/HomePage/google-play-app-badge.svg"
                            />
                        </a>

                    </div>
                    <div className="image-container">
                        <img
                            alt="Download the Phillips App"
                            className="phone"
                            src="https://content.phillips.com/HomePage/sell_with_us_phone.png"
                        />
                    </div>

                </div>

            </div>
        </div>
    );
}

SellWithUs.propTypes = {
    language: PropTypes.string.isRequired
}

export default SellWithUs;
