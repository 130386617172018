import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { selectElement } from './actions';
import setI18nLabel from '../../../utils/getI18nLabel';

const EditOverlay = ({ children, className, elementType, language, ...props }) => {
  const dispatch = useDispatch();
  const handleSelect = ({ target }) => {
    dispatch(selectElement({
      elementType,
      selectedElement: { ...props, language },
      type: target.value
    }))
  };
  const getI18nLabel = language.length > 0 ? setI18nLabel(language) : setI18nLabel('en');
  return (
    <div className={`edit-overlay__wrapper ${className}`}>
      <div className="edit-overlay">
        <button
          className="edit-overlay__button"
          onClick={handleSelect}
          type="button"
          value="edit"
        >
          {getI18nLabel({ label: 'edit' })}
        </button>
        <button
          className="edit-overlay__button"
          onClick={handleSelect}
          type="button"
          value="delete"
        >
          {getI18nLabel({ label: 'delete' })}
        </button>
      </div>
      {children}
    </div>
  );
};

EditOverlay.defaultProps = {
  className: '',
  hideDelete: false,
  language: "en",
  select: () => true
}
EditOverlay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  elementType: PropTypes.string.isRequired,
  hideDelete: PropTypes.bool,
  language: PropTypes.string,
  key: PropTypes.string.isRequired,
  select: PropTypes.func
};

export const applyEditOverlay = (editable, Component, elementType, hideDelete) => props => (
  editable ? (
    <EditOverlay
      className="edit-container"
      key={props.key}
      elementProps={props}
      elementType={elementType}
      hideDelete={hideDelete}
    >

      <Component {...props} editable={editable} />
    </EditOverlay>
  )
    : (
      <Component {...props} />
    )
);

export default EditOverlay;
