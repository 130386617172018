import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import PhillipsImage from '../PhillipsImage/PhillipsImage';
import { setI18nLinkLanguage } from '../../utils/getI18nLabel';


// Updated PhillipsArtist used on Homepage
const PhillipsMaker = ({
  imagePath, imageVersion, imageWidth, makerId, language, makerName, style, useCloudinary
}) => (
  <div
    className="phillips-artist"
    style={style}
  >
    <a href={setI18nLinkLanguage(language)(`/artist/${makerId}/${kebabCase(makerName)}`)}>
      <PhillipsImage
        cloudinary={useCloudinary}
        transformation="HomePageCarousel"
        imagePath={imagePath}
        version={imageVersion}
        width={imageWidth}
      />
      <div className="description">
        <h3>{makerName}</h3>
      </div>
    </a>
    {/* {editable
      ? null
      : <FollowArtist makerId={makerId} />
    } */}
  </div>
);

PhillipsMaker.defaultProps = {
  imagePath: '',
  imageVersion: '',
  imageWidth: '',
  language: 'en',
  style: {},
  useCloudinary: true
};

PhillipsMaker.propTypes = {
  imagePath: PropTypes.string,
  imageVersion: PropTypes.string,
  imageWidth: PropTypes.string,
  language: PropTypes.string,
  makerId: PropTypes.number.isRequired,
  makerName: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  useCloudinary: PropTypes.bool
};

export default PhillipsMaker;
