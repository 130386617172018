import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import HomePageContainer from './HomePage';
import * as homePageReducers from './reducers';
import routesMap from './routes';
import { configureStoreWithRouter } from '../../utils/configureStore';
import createInitialUserState from '../PhillipsUser/createInitialUserState';
import { language as languageReducer } from '../PhillipsI18N/reducers';
import * as userReducers from '../PhillipsUser/reducers';
import { hasLanguageInUrl } from '../PhillipsNav/routes';
import ConfiguredClientPortalProvider from '../PhillipsClientPortal/ConfiguredClientPortalProvider';

const initialState = ({ data: elements, editable, language, userJSON }) => {
  const userState = createInitialUserState(JSON.parse(userJSON));
  return {
    editable,
    elements,
    language,
    ...userState
  };
};

class HomePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log('errors: ', error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { title, location, language } = this.props;
    const { hasError } = this.state;
    const initialLanguage = hasLanguageInUrl.test(location) ? 'zh' : language;
    const store = configureStoreWithRouter({
      ...homePageReducers,
      ...userReducers,
      language: languageReducer
    },
      initialState({ ...this.props, language: initialLanguage }),
      routesMap,
      {
        basename: '/',
        initialEntries: this.props.location
      });
    return hasError
      ? (
        <section className="homepage" id="homepage">
          There was an error rendering the page.  Please check the console for more information.
        </section>
      )
      : (
        <Provider store={store}>
          <ConfiguredClientPortalProvider>
            <HomePageContainer title={title} interval={5000} editable={false} autoplay />
          </ConfiguredClientPortalProvider>
        </Provider>
      );
  }
}

HomePage.propTypes = {
  title: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
}

export { HomePage };
