import React, { PureComponent, createRef, Fragment } from 'react';
import classNames from 'classnames';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { Swipeable } from 'react-swipeable';
import breakpoints from '../utils/breakpoints';

const PhillipsCarouselItem = ({ children, width }) => (
  <li className="phillips-carousel-item" style={{ width }}>
    {children}
  </li>
);

class PhillipsCarousel extends PureComponent {
  constructor(props) {
    super(props);
    this.calcWidth = this.calcWidth.bind(this);
    this.changeIndex = this.changeIndex.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);
    this.setAutoplay = this.setAutoplay.bind(this);
    this.carouselRef = createRef();
    this.wrapperRef = createRef();
    this.intervalRef = createRef(null);
    this.state = {
      curIndex: 0,
      itemWidth: 278,
      itemsPerSlide: 4,
      nextDisabled: true,
      prevDisabled: true,
      wrapperWidth: 1110
    }
  }

  componentDidMount() {
    const setNumItems = size => this.props.sizes[size];
    breakpoints(mql => this.calcWidth(setNumItems(mql.size)));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.children.length !== this.props.children.length) {
      this.setState(state => ({
        ...state,
        wrapperWidth:
          Math.ceil(this.wrapperRef.current.clientWidth / state.itemsPerSlide) *
          nextProps.children.length
      }));
    }
  }

  setAutoplay() {
    if (isNull(this.intervalRef.current)) {
      this.intervalRef.current = setInterval(() => {
        this.setState(state => ({
          ...state,
          curIndex: state.curIndex + 1 < this.props.children.length ? state.curIndex + 1 : 0,
          nextDisabled: state.curIndex + 1 >= this.props.children.length - state.itemsPerSlide,
          prevDisabled: state.curIndex + 1 <= 0
        }));
      }, 5000);
    } else {
      clearInterval(this.intervalRef.current);
    }
  }

  changeIndex(int) {
    let { curIndex } = this.state;
    const { nextDisabled, prevDisabled } = this.state;

    const { children } = this.props;
    if ((int === -1 && prevDisabled) || (int === 1 && nextDisabled)) {
      return null;
    }
    curIndex += int;
    if (!isNull(this.intervalRef.current)) {
      this.setAutoplay();
    }
    this.setState(state => ({
      ...state,
      curIndex,
      nextDisabled: curIndex >= children.length - state.itemsPerSlide,
      prevDisabled: curIndex <= 0
    }));
    return this;
  }

  handleClick(e) {
    this.changeIndex(parseInt(e.target.value, 10));
  }

  handleSwipe({ dir }) {
    const int = dir === 'Left' ? 1 : dir === 'Right' ? -1 : null;
    if (!isNull(int)) {
      this.changeIndex(parseInt(int, 10));
    }
  }

  calcWidth(itemsPerSlide) {
    if (this.carouselRef.current === null) {
      return;
    }
    const itemWidth = Math.ceil(this.wrapperRef.current.offsetWidth / itemsPerSlide);
    this.setState(state => ({
      ...state,
      itemsPerSlide,
      itemWidth,
      nextDisabled: state.curIndex >= this.props.children.length - itemsPerSlide,
      prevDisabled: state.curIndex <= 0,
      wrapperWidth: itemWidth * this.props.children.length
    }));
  }

  render() {
    const innerRef = el => this.wrapperRef.current = el;
    return (
      <div
        className={classNames('phillips-carousel', this.props.classNames)}
        ref={this.carouselRef}
      >
        {this.props.children.length > this.state.itemsPerSlide ? (
          <Fragment>
            <button
              className={classNames('arrow prev', { disabled: this.state.prevDisabled })}
              value={-1}
              onClick={this.handleClick}
              type="button"
            />
            <button
              className={classNames('arrow next', { disabled: this.state.nextDisabled })}
              value={1}
              onClick={this.handleClick}
              type="button"
            />
          </Fragment>
        ) : null}
        {!isNull(this.props.carouselTitle) && this.props.carouselTitle.length > 0
          ? (
            <span
              className="phillips-carousel__title"
              dangerouslySetInnerHTML={{ __html: this.props.carouselTitle }}
            />
          )
          : null
        }
        {!isNull(this.props.carouselDesc) && this.props.carouselDesc.length > 0
          ? (
            <span
              className="phillips-carousel__description"
              dangerouslySetInnerHTML={{ __html: this.props.carouselDesc }}
            />
          )
          : null
        }
        <Swipeable
          delta={40}
          onSwiped={this.handleSwipe}
          className={classNames('carousel-wrapper', { 'carousel-wrapper--loading': !this.state.loaded })}
          innerRef={innerRef}
        >
          <ul
            className="carousel-track clearfix"
            style={{
              width: `${this.state.wrapperWidth}px`,
              transform: `translateX(-${this.state.itemWidth * this.state.curIndex}px)`,
              transition: 'transform 500ms ease'
            }}
          >
            {this.props.children.map(child => (
              <PhillipsCarouselItem width={this.state.itemWidth} key={child.key}>{child}</PhillipsCarouselItem>
            ))}
          </ul>
        </Swipeable>
        {!isNull(this.props.carouselCaption) && this.props.carouselCaption.length > 0
          ? (
            <span
              className="phillips-carousel__caption"
              dangerouslySetInnerHTML={{ __html: this.props.carouselCaption }}
            />
          )
          : null
        }
      </div>
    );
  }
}
/*

*/
PhillipsCarousel.defaultProps = {
  carouselDesc: '',
  carouselCaption: '',
  carouselTitle: '',
  classNames: '',
  sizes: {
    xl: 4,
    lg: 3,
    md: 3,
    sm: 1,
    xs: 1
  }
};

PhillipsCarousel.propTypes = {
  carouselDesc: PropTypes.string,
  carouselCaption: PropTypes.string,
  carouselTitle: PropTypes.string,
  classNames: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  sizes: PropTypes.shape({
    xl: PropTypes.number,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number
  })
};

export default PhillipsCarousel;
