import PropTypes from 'prop-types';

export const stickySessionPropTypes = {
  title: PropTypes.string.isRequired,
  lotsRange: PropTypes.string.isRequired,
  sessionTime: PropTypes.string.isRequired,
  cSessionTime: PropTypes.string.isRequired,
  sessionDate: PropTypes.string.isRequired,
  cSessionDate: PropTypes.string.isRequired
}

export const stickyPropTypes = {
  date: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  saleNumber: PropTypes.string.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.shape(stickySessionPropTypes)),
  timeState: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  auctionMobilityAuctionRowId: PropTypes.string.isRequired
}

export const stickyContainerPropTypes = {
  language: PropTypes.string.isRequired,
  getTranslatedString: PropTypes.func.isRequired,
  getI18nLink: PropTypes.func.isRequired,
  stickies: PropTypes.arrayOf(PropTypes.shape(stickyPropTypes))
};

export const defaultSticky = {
  date: "",
  locationName: "",
  saleNumber: "",
  subtitle: "",
  timeState: 0,
  time: "",
  title: "",
  uRL: "",
  auctionMobilityAuctionRowId: ""
};

export const featuredItemPropTypes = {
  active: PropTypes.bool,
  displayOrder: PropTypes.number,
  id: PropTypes.number,
  url: PropTypes.string,
  htmlCaption: PropTypes.string,
  type: PropTypes.string
};

export const defaultSlide = {
  active: false,
  displayOrder: 1,
  id: 0,
  htmlCaption: '',
  language: 'EN',
  type: 'slide',
  url: ''
};

export const featuredSliderPropTypes = PropTypes.arrayOf(PropTypes.shape(featuredItemPropTypes));
export const videoPropTypes = {
  active: PropTypes.bool,
  displayOrder: PropTypes.number,
  htmlCaption: PropTypes.string,
  id: PropTypes.number,
  saleInfo: PropTypes.string,
  type: PropTypes.string,
  thumbnail: PropTypes.string,
  videoUrl: PropTypes.string
};
export const defaultVideo = {
  active: false,
  displayOrder: 1,
  htmlCaption: '',
  id: 0,
  saleInfo: '',
  thumbnail: '',
  totalCount: 0,
  type: 'video',
  language: 'EN',
  videoUrl: ''
};

export const featuredVideosPropTypes = PropTypes.arrayOf(PropTypes.shape(videoPropTypes))

export const defaultContent = {
  active: false,
  displayOrder: 1,
  id: 0,
  url: '',
  htmlCaption: '',
  language: 'EN',
  type: 'featuredContent'
}

export const featuredContentPropTypes = PropTypes.arrayOf(PropTypes.shape(featuredItemPropTypes));

export const defaultBanner = {
  active: false,
  id: 0,
  desktopImageUrl: '',
  mobileImageUrl: '',
  type: 'lowerBanner',
  url: ''
}

export const lowerBannerPropTypes = {
  id: PropTypes.number,
  desktopImageUrl: PropTypes.string,
  mobileImageUrl: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  active: PropTypes.bool
};

