import { connect } from 'react-redux';
import { changeHeroIndex, loaded } from './actions';
import HomePageHero from './HomePageHero';

const mapStateToProps = ({ elements, language }, { editable, interval, autoplay }) => {
  // const liveAuction = elements.homePageLiveAuction;
  // const imageData = elements.featuredSlider;
  // const captionData = elements.featuredSlider.map(pick(['htmlCaption', 'url']));
  // return {
  //   imageData,
  //   currentHeroIndex,
  //   captionData,
  //   liveAuction,
  //   editable
  // };
  const { featuredSlider, cFeaturedSlider } = elements;
  return {
    featuredSlider, cFeaturedSlider,
    language,
    editable,
    interval,
    autoplay
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSlide: (index) => {
      dispatch(changeHeroIndex(index));
    },
    onLoad: (isloaded) => {
      dispatch(loaded(isloaded));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageHero);
