import React, { Fragment, useEffect, useRef } from 'react';
import classNames from 'classnames';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';

const getDetailWidgetHtml = (auctionMobilityLotRowId, endSale, status) => {
  const className = classNames('lot-information col-sm-2', { 'widget-connect-failure': status === 'widgetConnectFailure' });
  const htmlArray = [
    `<div class="${className}" id="detail-am-target-${auctionMobilityLotRowId}" lot-id="${auctionMobilityLotRowId}">`
  ];
  if (status === 'widgetConnectFailure') {
    htmlArray.push(`<img src="https://phillips.vo.llnwd.net/v1/web_prod/images/layout/reload_white.svg" alt="Unable to connect to server."/><h3>Network Connection</h3><p>We've detected a network problem with your 
connection. Please reload the page to see updated information.</p></div>`);
  } else {
    if (!endSale) {
      htmlArray.push('<div class="row"><am-timer class="col-sm-2"></am-timer></div><div class="row"><am-lot-status-label class="col-sm-1"></am-lot-status-label><am-reserve-label class="col-sm-1"></am-reserve-label></div>');
    }
    htmlArray.push(`<div 
class="row am-bid"><am-bid-status-label></am-bid-status-label><am-bid-count-label></am-bid-count-label></div><div 
class="row"><am-bid-box class="col-sm-2" v-on:action="onBidConfirmation"></am-bid-box></div><img class="hidden" 
src="https://phillips.vo.llnwd.net/v1/web_prod/images/layout/reload_white.svg" /></div>`);
  }
  return htmlArray.join('');
};
const getWidgetHtml = (auctionMobilityAuctionRowId, auctionMobilityLotRowId, endSale, saleTypeId) => `
  <div class="am-widget" id="am-target-${auctionMobilityAuctionRowId}-${auctionMobilityLotRowId}" lot-id="${auctionMobilityLotRowId}">
    ${saleTypeId === 6
    ? '<am-lot-timer class="am-widget__timer"></am-lot-timer>'
    : ''
  }
    <div class="row am-widget__lot ${endSale ? 'hide' : ''}">
      <am-lot-status-label class="am-widget__lot__status am-widget__lot__status--grid col-sm-2"></am-lot-status-label>
    </div>
    <div class="row am-widget__bid am-widget__bid--grid">
      <am-bid-status-label class="am-widget__bid__status"></am-bid-status-label>
      <am-bid-count-label class="am-widget__bid__count"></am-bid-count-label>
      <am-reserve-label class="am-widget__bid__reserve col-sm-2"></am-reserve-label>
    </div>
  </div>`;


const Widget = (props) => {
  const widgetEl = useRef(null);
  const {
    auctionMobilityAuctionRowId,
    layout,
    auctionMobilityLotRowId,
    endSale,
    getI18nLabel,
    saleTypeId,
    showDisclaimer } = props;
  // componentDidMount
  useEffect(() => {
    if (!isNull(widgetEl.current)) {
      widgetEl.current.innerHTML = layout === 'grid' ? getWidgetHtml(auctionMobilityAuctionRowId, auctionMobilityLotRowId, endSale, saleTypeId) : getDetailWidgetHtml(auctionMobilityLotRowId, endSale, 'widgetConnectSuccess', saleTypeId);
    }
  }, []);
  return (

    <Fragment>
      <div
        ref={widgetEl}
        className="row am-widget__wrapper"
      />
      {showDisclaimer
        ? (
          <p
            className={classNames('online-only-disclaimer', { 'hide': endSale || layout === 'grid' })}
            dangerouslySetInnerHTML={{ __html: getI18nLabel({ label: 'onlineOnlyDisclaimer' }) }}
          />
        )
        : null}
    </Fragment>
  );
};
Widget.defaultProps = {
  layout: 'grid',
  showDisclaimer: false
}
Widget.propTypes = {
  auctionMobilityAuctionRowId: PropTypes.string.isRequired,
  auctionMobilityLotRowId: PropTypes.string.isRequired,
  endSale: PropTypes.bool.isRequired,
  getI18nLabel: PropTypes.func.isRequired,
  layout: PropTypes.string,
  saleTypeId: PropTypes.number.isRequired,
  showDisclaimer: PropTypes.bool
};

export default Widget;